//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'config/creative/variables';
@import 'config/creative/custom-variables';
@import './node_modules/bootstrap/scss/bootstrap';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
// horizontal nav
@import 'custom/structure/horizontal-nav';

//Components
@import 'custom/components/mixins';
@import 'custom/components/accordions';
@import 'custom/components/avatar';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/badge';
@import 'custom/components/card';
@import 'custom/components/carousel';
@import 'custom/components/custom-forms';
@import 'custom/components/dropdown';
@import 'custom/components/docs';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/print';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/ribbons';
@import 'custom/components/switch';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/utilities';
@import 'custom/components/widgets';
@import 'custom/components/social';
@import 'custom/components/steps';
@import 'custom/components/preloader';
@import 'custom/components/hero';

//Pages
@import 'custom/pages/authentication';
@import 'custom/pages/components-demo';
@import 'custom/pages/error';
@import 'custom/pages/faq';
@import 'custom/pages/maintenance';
@import 'custom/pages/tasks';
@import 'custom/pages/email';
@import 'custom/pages/timeline';

// Vendors
@import 'vendor/bootstrap-datepicker.min';
@import 'vendor/jquery.toast.min';
@import 'vendor/select2.min';
@import 'vendor/jquery.toast.min';
@import 'vendor/bootstrap-timepicker.min';
@import 'vendor/jquery.bootstrap-touchspin.min';

// Plugins
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/britechart';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/datatable';
@import 'custom/plugins/dragula';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/quill-editor';
@import 'custom/plugins/select2';
@import 'custom/plugins/simplebar';
@import 'custom/plugins/toaster';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/bootstrap-timepicker';
@import 'custom/plugins/typehead';
@import 'custom/plugins/sparklines';
@import 'custom/plugins/timepicker';
@import 'custom/plugins/rating';
@import 'custom/plugins/react-select'; // react select style
@import 'custom/plugins/react-table'; // react table style
@import 'react-bootstrap-typeahead/css/Typeahead.css';

.text-green {
    color: #00b98b;
}

.text-blue {
    color: #23a8e0;
}

.mw-25 {
    min-width: 25%;
}
a[disabled] {
    pointer-events: none;
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.accordion-flush .accordion-item .accordion-button {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion-flush .accordion-item .accordion-button[aria-expanded='false'] {
    border-radius: 5px;
}

.form-check-label {
    font-weight: 400;
}

.task .table-responsive {
    height: 350px;
}

.btn-circle {
    width: 20px;
    height: 20px;
    padding: 1px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.quill {
    background-color: #f1f3fa;
    border: 2px solid var(--bs-light);
    .ql-toolbar {
        border: 0;
    }

    .ql-container {
        border: 0px solid #f1f3fa73 !important;
        .ql-editor {
            background: #fff;
            min-height: 9rem;
            position: relative;

            &:focus::after {
                position: absolute;
                transition: top 200ms linear;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                border: 2px solid var(--bs-primary);
            }
        }
    }
}

blockquote {
    border-left: 4px solid var(--bs-gray-400);
    padding: 5px 10px;
}

.ͼ1 .cm-scroller {
    font-family: 'Posterama Regular', sans-serif;
}

#editor-tabpane-preview .wmde-markdown {
    font-size: 13px !important;
}

.md-editor {
    --color-border-shadow: auto !important;
    background-color: transparent;

    .md-editor-toolbar-mode {
        display: none;
    }

    .cm-gutters {
        display: none;
    }
}

code:not(.code-highlight) {
    background-color: #d3dbe766 !important;
}
